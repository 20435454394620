import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ListButton,
  Loading,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useGetOne,
} from 'react-admin';
import { Grid, Checkbox } from '@mui/material';

import { useRecordContext } from 'ra-core';
import useApi from '../../hooks/useApi';
import React, { useEffect, useState } from 'react';
import { KycUserProps } from '../../utils/KycApi';
import { BlockUserAction } from '../BlockUser';
import { ChangeGroupAction } from '../ChangeGroup';
import { ChangeEmailAction } from '../ChangeEmail';
import { useResourceAccess } from '../../hooks/useAccess';
import { CreditReportingHistory } from '../CreditReportingHistory';
import { LinkToWallet } from '../LinkToWallet';
import { UpdateUserTagAction } from '../UpdateTag';
import { EmailNotificationList } from '../EmailNotificationList';
import { PushNotificationList } from '../PushNotificationList';
import { SMSNotificationList } from '../SMSNotificationList';
import dayjs from 'dayjs';
import { CommentsView } from '../CommentsView';

const KYCInfo = () => {
  const record = useRecordContext();
  const api = useApi().kyc;
  const [kyc, setKyc] = useState<KycUserProps>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (record != null) {
      api
        .getStatus(`${record.id}`)
        .then((res) => {
          setKyc(res);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [record]);

  if (loading) {
    return <Loading />;
  }

  return (
    <SimpleShowLayout
      record={{
        ...kyc,
        id: record.id,
      }}
    >
      <h3>KYC:</h3>

      <TextField source="kycStatus" label="KYC status" emptyText="N/A" />
      <DateField source="lastFullKYCCheck" label="lastFullKYCCheck" showTime={true} emptyText="N/A" />
      <TextField source="amlStatus" label="AML status" emptyText="N/A" />
      <DateField source="lastAMLCheck" label="lastAMLCheck" showTime={true} emptyText="N/A" />
      <LinkToKYC />
    </SimpleShowLayout>
  );
};

const UsersGroupDetails = () => {
  const user = useRecordContext();
  const { id } = user ?? { id: undefined };
  const { data, isLoading } = useGetOne(`groups`, { id });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <></>;
  }

  const { groups } = data;

  if (groups.length === 0) {
    return <></>;
  }

  return (
    <SimpleShowLayout>
      <h3>Roles:</h3>
      <ul>
        {groups.map((group: string) => (
          <li key={group}>{group}</li>
        ))}
      </ul>
    </SimpleShowLayout>
  );
};

const allTags = [
  { tag: 'Enhanced DD', title: 'High risk AML' },
  {
    tag: 'Category A',
    title: 'Vulnerable Category A',
  },
  { tag: 'Category A - HIGH', title: 'Vulnerable Category A - HIGH' },
  {
    tag: 'cancelled-due-to-dispute',
    title: 'Cancelled due to dispute',
  },
  {
    tag: 'CIFAS Perpetrator',
    title: 'CIFAS Perpetrator',
  },
  {
    tag: 'CIFAS Victim',
    title: 'CIFAS Victim',
  },
];

const UserActions = () => {
  const { canEdit, canChangePermissions } = useResourceAccess();
  const record = useRecordContext();
  const { data, isLoading, isError } = useGetOne(`groups`, { id: record?.id });

  if (!canEdit()) {
    return <></>;
  }

  const actions = [];
  if (record != null) {
    actions.push(<EditButton key="edit" />);
    if (record.status !== 'disabled') {
      actions.push(<BlockUserAction key="block" user={record} />, <ChangeEmailAction key="change-email" />);
    }
    if (!isLoading && !isError && data && canChangePermissions()) {
      actions.push(<ChangeGroupAction key="change-group" user={record} />);
    }
  }

  return <TopToolbar>{actions}</TopToolbar>;
};

export const LinkToCreditReporting = () => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }

  const { id } = record;
  return <ListButton to={`/users/${id}/creditreportinghistory`} label={'CreditReportingHistory'} />;
};

export const LinkToKYC = () => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }

  return (
    <ListButton
      to={`/kyc?displayedFilters=%7B%7D&filter=%7B%22userId%22%3A%22${record.id}%22%7D`}
      label={'KYC Checks'}
    />
  );
};

function getUserTags(bitsUser: any): string[] {
  const tags: any = bitsUser.mailchimpTags;
  try {
    if (tags == null) {
      return [];
    } else if (typeof tags === 'string') {
      return JSON.parse(tags);
    } else if (typeof tags === 'object') {
      return Object.keys(tags).filter((k) => tags[k]);
    }
  } catch (e) {
    console.error(e);
    return [];
  }
  return [];
}

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={25} defaultValue={25} />;

export const createDateRenderer = (column: string) => (record: any) =>
  record[column] && dayjs(record[column]).format('YYYY-MM-DD HH:mm');

const UserDetailsTab = () => {
  return (
    <Grid container>
      <Grid xs={8} item={true}>
        <SimpleShowLayout>
          <FunctionField
            source="mailchimpTags"
            label="Tags"
            render={(rec: any) =>
              allTags.map(({ tag, title }, index: number) => (
                <UpdateUserTagAction
                  key={`update-tag-${index}`}
                  user={rec}
                  tag={tag}
                  tagLabel={`${title}`}
                  tagValue={!getUserTags(rec).includes(tag)}
                >
                  <Checkbox title={tag} checked={getUserTags(rec).includes(tag)} />
                  {title}
                </UpdateUserTagAction>
              ))
            }
          />
        </SimpleShowLayout>
      </Grid>
      <Grid container item xs={8} spacing={2}>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="dateBirth" />
            <TextField source="email" />
            <BooleanField source="isEmailVerified" />
            <TextField source="phoneNumber" />
            <ReferenceField reference={'phone3ds'} source={'id'} label={'3DS phone number'}>
              <TextField source="phone" />
            </ReferenceField>
            <TextField source="address1" />
            <TextField source="address2" />
            <TextField source="address3" />
            <TextField source="city" />
            <TextField source="postCode" />
            <TextField source="status" />
            <TextField source="income" />
            <TextField source="expenses" />
            <TextField source="lastLogin" />
            <TextField source="dateCreate" />
            <TextField source="dateUpdate" />
            <TextField source="isAdmin" />
            <TextField aria-multiline source="notes" />
            <BooleanField source="disabledReporting" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={5}>
          <CommentsView source="comments" />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <LinkToWallet />
          <LinkToCreditReporting />
          <LinkToKYC />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={8}>
        <KYCInfo />
      </Grid>
      <Grid item xs={4}>
        <UsersGroupDetails />
      </Grid>
      <Grid item xs={8}>
        <ReferenceManyField reference={'userhistory'} target={'userId'} label="Events" pagination={<PostPagination />}>
          <Datagrid bulkActionButtons={false}>
            <FunctionField source="timestamp" render={createDateRenderer('timestamp')} label={'Created'} />
            <TextField source="eventMessage" />
            <ReferenceField reference={'users'} source={'initiator'} label={'ChangedBy'}>
              <TextField source="email" />
            </ReferenceField>
            <TextField source="payload.reason" label={'Reason'} />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </Grid>
  );
};

export const UserDetails = () => {
  return (
    <Show actions={<UserActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General">
          <UserDetailsTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="CreditHistory">
          <CreditReportingHistory />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Emails">
          <EmailNotificationList />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Push">
          <PushNotificationList />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="SMS">
          <SMSNotificationList />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
